import React from 'react';
import { useRef, useEffect } from 'react';
import Layout from '../components/layout';

const ItiliteExpense = () => {

    const tl = useRef();
    const tl2 = useRef();
    const figmaTl = useRef();
    const introAnimationRef = useRef();
    const contentContainer = useRef();
    const workTypeArrayRef = useRef();
    const largeIphoneRef = useRef();
    workTypeArrayRef.current = [];

    const addToworkTypeArrayRef = (el) => {
        if (el && !workTypeArrayRef.current.includes(el)) {
            workTypeArrayRef.current.push(el);
        }
    };




    let mouseHoverRef = useRef({
        x:1
      });


    return (
        <Layout mouseHoverState={mouseHoverRef.current}>
            <div className="bg-black">
            <iframe 
            className='min-h-screen'
                src="https://tally.so/r/wzovMn" 
                width="100%" height="100%" 
                frameBorder="0" 
                marginHeight="0" marginHeight="0" title="Get in touch"></iframe>
            </div>


        
         
        </Layout>

    )
}


export default ItiliteExpense;